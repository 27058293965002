import { ValueOf } from "../helpers/enums";
import { VERCEL_ENV } from "./config";

const isStripeLiveMode = VERCEL_ENV === "production";

export enum ForteStripeProductKey {
  FREE = "FREE",
  BASIC = "BASIC",
  PRO = "PRO",
  ELITE = "ELITE",
  TEACHER_DAY_PASS = "TEACHER_DAY_PASS",
}

export const ForteStripeProductIdByForteStripeProductKey = {
  [ForteStripeProductKey.FREE]: isStripeLiveMode
    ? "prod_QzHvcJUFg8ANnj"
    : "prod_R03IrIx1gKc2CE",
  [ForteStripeProductKey.BASIC]: isStripeLiveMode
    ? "prod_QzHq8Dg0QgKx45"
    : "prod_QzbtelcDmAYizt",
  [ForteStripeProductKey.PRO]: isStripeLiveMode
    ? "prod_QzHtKNNPtyoz3f"
    : "prod_QzbtU2sP0tjQmB",
  [ForteStripeProductKey.ELITE]: isStripeLiveMode
    ? "prod_QzHun18o899lWI"
    : "prod_R03KGCPz6cQ8bO",
  [ForteStripeProductKey.TEACHER_DAY_PASS]: isStripeLiveMode
    ? "" // TODO Update once DayPass goes live
    : "prod_RI3SpLAYBS8UhL",
} as Record<ForteStripeProductKey, string>;

export type ForteStripeProductId = ValueOf<
  typeof ForteStripeProductIdByForteStripeProductKey
>;

export enum StripeCouponId {
  LEGACY_TEACHER_FREE_ELITE = "legacy-teacher-free-elite",
  PROGRAM_TEACHER_FREE_ELITE = "program-teacher-free-elite",
  VERIFIED_TEACHER_FREE_PRO = "verified-teacher-free-pro",
  ACTIVE_CONNECT_TEACHER_FREE_PRO_1_MONTH = "active-connect-teacher-free-pro-1-month",
  TAKELESSONS_TEACHER_FREE_PRO_3_MONTH = "takelessons-teacher-free-pro-3-month",
}

export enum ForteProductName {
  TEACHER_DAY_PASS = "Teacher Day Pass",
  FREE = "Free",
  BASIC = "Basic",
  PRO = "Pro",
  ELITE = "Elite",
}

export enum TeacherSubscriptionProductType {
  TEACHER_DAYPASS = "teacher-daypass",
  TEACHER_SUBSCRIPTION = "teacher-subscription",
}
