import first from "lodash/first";

import { ForteProductName } from "../constants/stripe";

import {
  KnownFeatureFlagName as GraphqlKnownFeatureFlagName,
  TeachingRelationshipEntitlementsFragment,
} from "@graphql";
import { KnownFeatureFlagName as PrismaKnownFeatureFlagName } from "@prisma/client";

type UserWithFeatureEntitlements = {
  featureEntitlements: Array<
    PrismaKnownFeatureFlagName | GraphqlKnownFeatureFlagName
  >;
  id: null | string;
};

type UserOrEntitlements =
  | UserWithFeatureEntitlements
  | Array<PrismaKnownFeatureFlagName | GraphqlKnownFeatureFlagName>;

/**
 * Single access point wrapper around all feature entitlements, if that's convenient.
 * Maybe we'll also expose the individual functions if that's more ergonomic
 */
export function FeatureEntitlements(
  userOrEntitlements: null | UserOrEntitlements,
) {
  return {
    recordingLimit: () => getRecordingLimitForUser(userOrEntitlements),
    byosStudentLimit: () => getBYOSStudentLimitForUser(userOrEntitlements),
    hasLessonBundleSupport: () =>
      hasLessonBundleSupportForUser(userOrEntitlements),
  };
}

export const allTeachingRelationshipEntitlements =
  new Set<PrismaKnownFeatureFlagName>([
    PrismaKnownFeatureFlagName.ILE_IPAD_SUPPORT,
    PrismaKnownFeatureFlagName.ILE_MOBILE_SUPPORT,
    PrismaKnownFeatureFlagName.LESSON_BUNDLES,
    PrismaKnownFeatureFlagName.TEXT_CHAT,
    PrismaKnownFeatureFlagName.SHARED_NOTES,
    PrismaKnownFeatureFlagName.TEACHING_MATERIALS,
    PrismaKnownFeatureFlagName.BASIC_FEATURES, // TODO proper permission for ILE
  ]);

export function getFeatureEntitlementsForTeachingRelationship(
  teachingRelationship: TeachingRelationshipEntitlementsFragment,
) {
  const teacherWithEntitlements = {
    id: teachingRelationship.teacherId,
    featureEntitlements: teachingRelationship.teachingRelationshipEntitlements,
  };

  return {
    hasLessonBundleSupport: () =>
      hasLessonBundleSupportForUser(teacherWithEntitlements),
    // hasILEIpadSupport: () => hasILEIpadSupportForUser(teacherWithEntitlements),
    // hasILEMobileSupport: () =>
    //   hasILEMobileSupportForUser(teacherWithEntitlements),
    hasSharedNotesSupport: () =>
      hasSharedNotesSupportForUser(teachingRelationship),
    hasTeachingMaterialsSupport: () =>
      hasTeachingMaterialsSupportForUser(teachingRelationship),
    hasTextChatSupport: () => hasTextChatSupportForUser(teachingRelationship),
  };
}

export function getRecordingLimitForUser(
  userOrEntitlements: null | UserOrEntitlements,
): number {
  return getValue(
    userOrEntitlements,
    {
      [GraphqlKnownFeatureFlagName.TIER_3_RECORDING_LIMIT]:
        Number.POSITIVE_INFINITY,
      [GraphqlKnownFeatureFlagName.TIER_2_RECORDING_LIMIT]: 15,
      [GraphqlKnownFeatureFlagName.TIER_1_RECORDING_LIMIT]: 3,
    },
    0,
  );
}

/**
 * We only added tier-1-recording-limit on 12/3/24, just before Entitlements went into effect.
 * Stripe only updates a Customers' entitlements when a Subscription is *created* and when an
 * existing Subscription gets renewed at the end of a billing cycle.
 * Because of this, we have existing ForteBasic teachers who wont get the tier-1-recording-limit
 * entitlement for ~30 days. This hack is to instead calculate it based on the SubscriptionProduct
 */
export function HACK_getRecordingLimitFromSubscription(
  stripeProductName: null | ForteProductName,
) {
  if (
    stripeProductName === ForteProductName.ELITE ||
    stripeProductName === ForteProductName.TEACHER_DAY_PASS
  ) {
    return Number.POSITIVE_INFINITY;
  } else if (stripeProductName === ForteProductName.PRO) {
    return 15;
  } else if (stripeProductName === ForteProductName.BASIC) {
    return 3;
  } else {
    return 0;
  }
}

export function getBYOSStudentLimitForUser(
  userOrEntitlements: null | UserOrEntitlements,
): number {
  const defaultValue = 0;
  return getValue(
    userOrEntitlements,
    {
      [GraphqlKnownFeatureFlagName.TIER_3_BYOS_LIMIT]: Number.POSITIVE_INFINITY,
      [GraphqlKnownFeatureFlagName.TIER_2_BYOS_LIMIT]: 5,
      [GraphqlKnownFeatureFlagName.TIER_1_BYOS_LIMIT]: 1,
      [GraphqlKnownFeatureFlagName.TIER_0_BYOS_LIMIT]: defaultValue,
    },
    defaultValue,
  );
}

export function hasSchedulingSupportForUser(
  userOrEntitlements: null | UserOrEntitlements,
): boolean {
  return getValue(
    userOrEntitlements,
    {
      [GraphqlKnownFeatureFlagName.BASIC_SCHEDULING]: true,
    },
    false,
  );
}

export function isBeyondTeacherForteDirectLimit(
  tr: TeachingRelationshipEntitlementsFragment,
): boolean {
  return tr.isBeyondTeacherForteDirectLimit;
}

// export function hasILEIpadSupportForUser(
//   userOrEntitlements: null | UserOrEntitlements,
// ): boolean {
//   return getValue(
//     userOrEntitlements,
//     {
//       [GraphqlKnownFeatureFlagName.ILE_IPAD_SUPPORT]: true,
//     },
//     false,
//   );
// }

// export function hasILEMobileSupportForUser(
//   userOrEntitlements: null | UserOrEntitlements,
// ): boolean {
//   return getValue(
//     userOrEntitlements,
//     {
//       [GraphqlKnownFeatureFlagName.ILE_MOBILE_SUPPORT]: true,
//     },
//     false,
//   );
// }

export function hasLessonBundleSupportForUser(
  userOrEntitlements: null | UserOrEntitlements,
): boolean {
  return getValue(
    userOrEntitlements,
    {
      [GraphqlKnownFeatureFlagName.LESSON_BUNDLES]: true,
    },
    false,
  );
}

export function hasSharedNotesSupportForUser(
  teachingRelationship: TeachingRelationshipEntitlementsFragment,
): boolean {
  return (
    !isBeyondTeacherForteDirectLimit(teachingRelationship) &&
    getValue(
      teachingRelationship,
      {
        [GraphqlKnownFeatureFlagName.SHARED_NOTES]: true,
      },
      false,
    )
  );
}

export function hasTeachingMaterialsSupportForUser(
  teachingRelationship: TeachingRelationshipEntitlementsFragment,
): boolean {
  return (
    !isBeyondTeacherForteDirectLimit(teachingRelationship) &&
    getValue(
      teachingRelationship,
      {
        [GraphqlKnownFeatureFlagName.TEACHING_MATERIALS]: true,
      },
      false,
    )
  );
}

export function hasTextChatSupportForUser(
  teachingRelationship: TeachingRelationshipEntitlementsFragment,
): boolean {
  return (
    !isBeyondTeacherForteDirectLimit(teachingRelationship) &&
    getValue(
      teachingRelationship,
      {
        [GraphqlKnownFeatureFlagName.TEXT_CHAT]: true,
      },
      false,
    )
  );
}

export function getValue<T extends number | boolean>(
  teachingRelationship: TeachingRelationshipEntitlementsFragment,

  // Order Matters: Sorted from highest priority to lowest priority
  map: Partial<Record<GraphqlKnownFeatureFlagName, T>>,

  // Value to use if the userOrEntitlements doesnt have a matching entitlement in the candidates
  defaultValue: T,
): T;
export function getValue<T extends number | boolean>(
  userOrEntitlements: null | UserOrEntitlements,

  // Order Matters: Sorted from highest priority to lowest priority
  map: Partial<Record<GraphqlKnownFeatureFlagName, T>>,

  // Value to use if the userOrEntitlements doesnt have a matching entitlement in the candidates
  defaultValue: T,
): T;
export function getValue<T extends number | boolean>(
  userOrEntitlements:
    | null
    | UserOrEntitlements
    | TeachingRelationshipEntitlementsFragment,

  // Order Matters: Sorted from highest priority to lowest priority
  map: Partial<Record<GraphqlKnownFeatureFlagName, T>>,

  // Value to use if the userOrEntitlements doesnt have a matching entitlement in the candidates
  defaultValue: T,
): T {
  const featureEntitlements = (() => {
    if (!userOrEntitlements) {
      return [];
    }

    if (Array.isArray(userOrEntitlements)) {
      return userOrEntitlements;
    }

    if ("teachingRelationshipEntitlements" in userOrEntitlements) {
      return userOrEntitlements.teachingRelationshipEntitlements;
    }

    return userOrEntitlements.featureEntitlements;
  })();

  const candidates = Object.keys(map) as Array<GraphqlKnownFeatureFlagName>;

  const foundCandidates = candidates.filter((candidate) => {
    return featureEntitlements.includes(candidate);
  });

  const entitlementToUse = first(foundCandidates);

  return (entitlementToUse && map[entitlementToUse]) ?? defaultValue;
}
