/* eslint-disable unicorn/prefer-node-protocol */
import { ActiveViewEnum } from "constants/matching";
import keyBy from "lodash/keyBy";
import mapValues from "lodash/mapValues";

import { type UpdateBookmarkDataT } from "@components/BookmarkedTeacherProvider";
import {
  type BudgetQuestionOptions,
  type ExperienceQuestionOptions,
  type FrequencyQuestionOptions,
} from "@components/MatchingSurvey/leadQualifierQuestionTextHelpers";

import {
  type AgeRangeOption,
  type AspirationLevelOption,
  type GenreOption,
  type MusicTheoryLevelOption,
  RecentlyBrowsedTeachersInOrganizationFragment,
  type SkillLevelOption,
  UserTeacherOfFlagType,
} from "@graphql";

export type SignupModeFieldsT = {
  learnerName: string;
};

type LearnerOfT =
  RecentlyBrowsedTeachersInOrganizationFragment["learnersOf"][number];

export function updateBookmarksState(
  learnersOf: LearnerOfT[],
  updateBookmarkData: UpdateBookmarkDataT,
) {
  const keyed = keyBy(
    learnersOf.flatMap((learnerOf) => learnerOf.allUserTeacherOfFlags),
    (userTeacherOfFlag) => userTeacherOfFlag.id,
  );

  const updateObject = mapValues(
    keyed,
    (userTeacherOfFlag) =>
      userTeacherOfFlag.flag === UserTeacherOfFlagType.BOOKMARKED,
  );

  updateBookmarkData(updateObject);
}

export function isOnDateTimePaymentOrBookedStep(
  activeView: ActiveViewEnum | undefined,
): boolean {
  return (
    activeView === ActiveViewEnum.DATE_TIME ||
    activeView === ActiveViewEnum.PAYMENT ||
    activeView === ActiveViewEnum.BOOKED
  );
}

export type MatchingSurveyFieldsT = {
  ageGroup: AgeRangeOption;
  aspirationLevel: AspirationLevelOption;
  genres: GenreOption[];
  musicTheoryLevel: MusicTheoryLevelOption;
  skillLevel: SkillLevelOption;
};

export const SIGNUP_MODE_FORM_KEY = "signupModeForm";
export const SURVEY_FORM_KEY = "matchingSurveyForm";
export const SIGNUP_FORM_KEY = "matchingSignupForm";
export const SIGNIN_FORM_KEY = "matchingSignInForm";
export const SURVEY_LEAD_QUALIFIER_FORM_KEY = "matchingSurveyLeadQualifierForm";

type MatchingSignupFieldsT = {
  creditRedemptionCode: null | string;
  email: string;
  givenName: string;
  password: string;
};

type MatchingSignInFieldsT = {
  emailOrUsername: string;
  password: string;
};

type MatchingSurveyLeadQualifierFieldsT = {
  budget: BudgetQuestionOptions;
  experience: ExperienceQuestionOptions;
  frequency: FrequencyQuestionOptions;
};

export type SignupAndSubmitMatchingSurveyFormT = {
  [SIGNIN_FORM_KEY]: MatchingSignInFieldsT;
  [SIGNUP_FORM_KEY]: MatchingSignupFieldsT;
  [SIGNUP_MODE_FORM_KEY]: SignupModeFieldsT;
  [SURVEY_FORM_KEY]: MatchingSurveyFieldsT & MatchingSurveyLeadQualifierFieldsT;
};

export function componentsToUserTeacherOfFlagId({
  studentId,
  teacherOfId,
}: {
  studentId: string;
  teacherOfId: string;
}): string {
  const userTeacherOfFlagId = `${teacherOfId}:${studentId}`;
  return userTeacherOfFlagId;
}
